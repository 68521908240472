import React from 'react';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';

const AppLoader = () => {
  return (
    <Box
      className="app-loader"
      sx={{
        marginLeft: { lg: '140px' },
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default AppLoader;
