import axios from './ApiConfig';
import { AxiosError } from 'axios';

export type AxiosResponseErrors = { [key: string]: string[] };

export type ResponseWithErrors = {
  errors: AxiosResponseErrors;
  message: string;
};

export type AxiosResponseWithErrors = {
  data: ResponseWithErrors;
};

export const isResponseWithErrors = (
  response: any
): response is ResponseWithErrors => {
  return response && 'errors' in response && 'message' in response;
};

export type Response401Unauthorized = {
  response: AxiosError;
  status: 401;
};

export type Response403Forbidden = {
  response: AxiosError;
  status: 403;
};
export type Response404NotFound = {
  response: AxiosError;
  status: 404;
};

export type Response422UnprocessableContent = {
  response: AxiosError;
  status: 422;
};

export type Response204NoContent = {
  status: 204;
};

export function isResponse401Unauthorized(
  error: any
): error is Response401Unauthorized {
  return (
    error &&
    'response' in error &&
    'status' in error.response &&
    error.response.status === 401
  );
}

export function isResponse403Forbidden(
  error: any
): error is Response403Forbidden {
  return (
    error &&
    'response' in error &&
    'status' in error.response &&
    error.response.status === 403
  );
}
export function isResponse404NotFound(
  error: any
): error is Response404NotFound {
  return (
    error &&
    'response' in error &&
    'status' in error.response &&
    error.response.status === 404
  );
}

export function isResponse422UnprocessableContent(
  error: any
): error is Response422UnprocessableContent {
  return (
    error &&
    'response' in error &&
    'status' in error.response &&
    error.response.status === 422
  );
}

export function isPostDataResponse204NoContent(
  response: any
): response is Response204NoContent {
  return response && 'status' in response && response.status === 204;
}

export default axios as any;
