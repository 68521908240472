import { SkillType } from '@crema/models/apps/Guide';
import { Box, Tooltip } from '@mui/material';
import type { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import { orderBy } from 'lodash';

export const getStringFromHtml = (htmlContent: string) => {
  return htmlContent.replace(/(<([^>]+)>)/gi, '');
};

export const generateUniqueID = () => {
  return `v1-${Date.now()}-${Math.floor(Math.random() * (9e12 - 1)) + 1e12}`;
};

export const ellipsisLines = (
  content: string,
  placement: TooltipProps['placement'] = 'top'
) => {
  if (content) {
    return (
      <Tooltip placement={placement} title={content}>
        <Box
          style={{
            display: 'block',
            width: '100%',
            maxWidth: 250,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {content}
          {content.length > 40 && '...'}
        </Box>
      </Tooltip>
    );
  } else {
    return handleNullValue(content);
  }
};

export const handleNullValue = (value: string | null) => {
  if (value === null) {
    return '-';
  } else {
    return value;
  }
};

export const formatCurrency = (
  value: number,
  currencyFormat: {
    language: string;
    currency: string;
  },
  decimalDigits: number
) => {
  return new Intl.NumberFormat(currencyFormat?.language || 'en-IN', {
    style: 'currency',
    currency: currencyFormat?.currency || 'INR',
    minimumFractionDigits: decimalDigits,
    maximumFractionDigits: decimalDigits,
  }).format(value);
};

export const filterByParts = (text: string, filterString: string) => {
  const searchWords = filterString.split(/\s+/);
  const parts = searchWords
    .filter(function (searchWord) {
      return searchWord;
    }) // Remove empty words
    .reduce(function (chunks, searchWord) {
      // searchWord = sanitize(searchWord);
      // FindChunks.sanitize

      const caseSensitive = false;
      const autoEscape = true;

      if (autoEscape) {
        searchWord = escapeRegExpFn(searchWord);
      }

      const regex = new RegExp(searchWord, caseSensitive ? 'g' : 'gi');

      let match: RegExpExecArray | null = regex.exec(text);
      while (match) {
        const start = match.index;
        const end = regex.lastIndex;
        // We do not return zero-length matches
        if (end > start) {
          chunks.push({ start: start, end: end });
        }

        // Prevent browsers like Firefox from getting stuck in an infinite loop
        // See http://www.regexguru.com/2008/04/watch-out-for-zero-length-matches/
        if (match.index === regex.lastIndex) {
          regex.lastIndex++;
        }

        match = regex.exec(text);
      }

      return chunks;
    }, [] as { start: number; end: number }[]);

  return parts.length > 0;
};

function escapeRegExpFn(searchWord: string): string {
  return searchWord.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
}

export function canBeNumber(value: string): boolean {
  return !isNaN(Number(value));
}

export function canBePositiveNumber(value: string): boolean {
  return !isNaN(Number(value)) && Number(value) > 0;
}

export const encodeBase64 = (data: string) => {
  return Buffer.from(data).toString('base64');
};
export const decodeBase64 = (data: string) => {
  return Buffer.from(data, 'base64').toString('ascii');
};

export const filterSkillList = (
  list: SkillType[],
  text: string,
  selectedSkills: number[]
) => {
  const searchTextLower = text.toLowerCase();
  return searchTextLower
    ? list.filter((skill) => {
        if (skill.title.toLowerCase().includes(searchTextLower)) {
          return true;
        }

        if (skill.definition.toLowerCase().includes(searchTextLower)) {
          return true;
        }

        if (
          skill.aliases_list &&
          skill.aliases_list.toLowerCase().includes(searchTextLower)
        ) {
          return true;
        }

        if (selectedSkills.length > 0 && selectedSkills.includes(skill.id)) {
          return true;
        }

        return false;
      })
    : list;
};

export const orderSkillList = (list: SkillType[], selectedIds: number[]) => {
  return list;

  const selectedSkills = list.filter((skill) => selectedIds.includes(skill.id));
  const unselectedSkills = list.filter(
    (skill) => !selectedIds.includes(skill.id)
  );

  // Sort selected skills alphabetically by name
  const sortedSelectedSkills = orderBy(selectedSkills, ['title'], ['asc']);

  // Combine the sorted selected skills with the unselected skills
  const sortedList = [...sortedSelectedSkills, ...unselectedSkills];

  return sortedList;
};

export const removeScriptTags = (html: string) => {
  return html.replace(
    /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
    ''
  );
};

// eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
export const isString = (value: any): value is string => {
  return typeof value === 'string';
};

export const hashString = (str: string) => {
  return str
    .split('')
    .reduce((prev, curr) => (Math.imul(31, prev) + curr.charCodeAt(0)) | 0, 0);
};

// eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
export const convertToString = (body: any): string => {
  return body.toString();
};

// const removeScriptTags = (html: string) => {
//   // Create a temporary div element
//   const tempDiv = document.createElement('div');
//
//   // Set its HTML content to the provided HTML string
//   tempDiv.innerHTML = html;
//
//   // Find all <script> elements in the tempDiv
//   const scripts =
//     tempDiv.getElementsByTagName('script');
//
//   // Remove each <script> element found
//   while (scripts.length) {
//     if (scripts[0].parentNode) {
//       scripts[0].parentNode.removeChild(scripts[0]);
//     }
//   }
//
//   // Return the modified HTML without <script> tags
//   return tempDiv.innerHTML;
// };
