import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { apiUrl } from '@crema/constants/AppConst';
import { apiToken } from '@crema/helpers';
import { destroyCookie } from 'nookies';

const apiConfig = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

apiConfig.interceptors.response.use(
  (res: AxiosResponse<any, any>) => res,
  (err: any) => {
    if (err.response && err.response.data.msg === 'Token is not valid') {
      console.log('Need to logout user');
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  }
);

apiConfig.interceptors.request.use(
  (value: InternalAxiosRequestConfig<any>) => {
    // console.log('value', value);

    const token =
      (typeof sessionStorage !== 'undefined'
        ? sessionStorage?.getItem('token')
        : undefined) ||
      (typeof localStorage !== 'undefined'
        ? localStorage?.getItem('token')
        : undefined) ||
      apiToken;

    if (token) {
      value.headers.Authorization = `Bearer ${token}`;
    }

    return value;
  },
  (err: any) => {
    console.log('reject', err);
    if (err.response && err.response.data.msg === 'Token is not valid') {
      console.log('Need to logout user');
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  }
);

export const setAuthToken = (token?: string) => {
  if (token) {
    // eslint-disable-next-line no-debugger
    apiConfig.defaults.headers.common.Authorization = `Bearer ${token}`;
    // localStorage.setItem('token', token);
  } else {
    delete apiConfig.defaults.headers.common.Authorization;
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');

    destroyCookie(null, 'auth-token', {
      path: '/',
      sameSite: 'strict',
      maxAge: 0,
    });
  }
};

export default apiConfig;
