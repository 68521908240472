export const parseJson = (jsonString: string | null, defaultReturn = {}) => {
  if (jsonString === null) return defaultReturn;

  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return defaultReturn;
  }
};

// eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
export const isSyntheticEvent = (event: any): event is React.SyntheticEvent => {
  return event.nativeEvent instanceof Event;
};
