import { frontendBaseUrl } from '@crema/constants/AppConst';
import { forEach } from 'lodash';

/**
 * Get Assets Url
 * @param fileUrl string
 * @returns fileUrl string
 */
export const getAssetsUrl = (fileUrl: string) => {
  return `/assets/images/${fileUrl}`;
};

export const stripEndingSlack = (string: string | undefined) => {
  if (string === undefined || !string) {
    return '';
  }

  return string.replace(/\/$/, '');
};

export const learningHubArticleUrl = (slug: string) => {
  return `${frontendBaseUrl}/learning-hub/${slug}`;
};

export const convertOrderToQueryString = (
  groupKey: string,
  group: { [key: string]: string }[]
): string => {
  const orderParts: string[] = group.map((item, index) =>
    Object.entries(item)
      .map(
        ([key, value]) =>
          `${groupKey}[${index}][${key}]=${encodeURIComponent(value)}`
      )
      .join('&')
  );
  return orderParts.join('&');
};

export const createFullQueryString = (
  params: Record<string, string>,
  groups: { [key: string]: any[] }
): string => {
  const searchParams = new URLSearchParams(params);

  forEach(groups, (group, index) => {
    const groupQueryString = convertOrderToQueryString(index, group);

    if (groupQueryString) {
      groupQueryString.split('&').forEach((part) => {
        const [key, value] = part.split('=');
        searchParams.append(key, value);
      });
    }
  });

  return searchParams.toString();
};

export const fullDomainUrl = (url: string) => {
  return `${frontendBaseUrl}${url}`;
};

export const canonicalDomainUrl = (url: string) => {
  return `https://interviewsup.com${url}`;
};
