import { parseJson } from './ObjectHelper';

// eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
export const readFromStorage = (key: string, defaultValue: any) => {
  if (typeof localStorage === 'undefined') {
    return defaultValue;
  }

  const jsonString = localStorage.getItem(key);

  if (jsonString === null) {
    return defaultValue;
  }

  return parseJson(jsonString, defaultValue);
};
