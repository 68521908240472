import React, { ReactNode, createContext, useContext } from 'react';
import { postDataApi } from '@crema/hooks/APIHooks';

import { useInfoViewActionsContext } from '@crema/context/InfoViewContextProvider';
import { useAuthMethod } from '@crema/hooks/AuthHooks';

export type SearchContextType = {
  searchText: string;
  searchLabel: string;
  results: string[];
};

export type SearchActionContextType = {
  setSearchText: (text: string) => void;
  resetSearchText: () => void;
  setSearchLabel: (text: string) => void;
};

const ContextState: SearchContextType = {
  searchText: '',
  searchLabel: '',
  results: [],
};

const SearchContext = createContext<SearchContextType>(ContextState);
const SearchActionsContext = createContext<SearchActionContextType>({
  setSearchText: () => {},
  resetSearchText: () => {},
  setSearchLabel: () => {},
});

export const useSearchContext = () => useContext(SearchContext);

export const useSearchActionsContext = () => useContext(SearchActionsContext);

type Props = {
  children: ReactNode;
};

export const SearchContextProvider = ({ children }: Props) => {
  // console.log('Start ScrumContextProvider');

  // const [
  //   { apiData: guideList },
  //   { setPage: setListingPage, reCallAPI: reloadGuides },
  // ] = useGetDataApi<GuideCollectionType>(
  //   '/guides/list',
  //   {
  //     meta: {
  //       current_page: 1,
  //       from: 0,
  //       last_page: 0,
  //       path: '',
  //       per_page: 0,
  //       to: 0,
  //       total: -1,
  //     },
  //     data: [],
  //   },
  //   {
  //     extended: true,
  //     jobRoleId: jobRoleId,
  //     page: page,
  //   }
  // );

  const [searchText, setSearchText] = React.useState('');
  const [searchLabel, setSearchLabel] = React.useState('');
  const [results, setResults] = React.useState<string[]>([]);

  //
  // const [skillsQuestions, setSkillsQuestions] =
  //   React.useState<SkillQuestionsSetType>(new Map());
  //
  // const [pendingSkillsQuestions, setPendingSkillsQuestions] =
  //   React.useState<PendingSkillQuestionsSetType>(guide ? map(keyBy(guide.skills, 'id'), function (e) {
  //     return Promise.resolve([])
  //   }) : {});

  const infoViewActionsContext = useInfoViewActionsContext();
  const authContext = useAuthMethod();

  const addQuestionOnSkill = (guideId: number, skillId: number) => {
    return new Promise<boolean>((resolve, reject) => {
      // console.log('addQuestionOnSkill', skillId);
      // postDataApi<StatusResponseType>(
      //   `/guides/${guideId}/add-question`,
      //   infoViewActionsContext,
      //   authContext,
      //   {
      //     skill_id: skillId,
      //   }
      // ).then((data) => {
      //   if (data.status.toString() === '201') {
      //     resolve(true);
      //   } else if (data.status.toString() === '204') {
      //     resolve(false);
      //   } else {
      //     reject(false);
      //   }
      // });
    });
  };

  const resetSearchText = () => {
    setSearchText('');
  };

  return (
    <SearchContext.Provider
      value={{
        searchText,
        searchLabel,
        results,
      }}
    >
      <SearchActionsContext.Provider
        value={{
          setSearchText,
          resetSearchText,
          setSearchLabel,
        }}
      >
        {children}
      </SearchActionsContext.Provider>
    </SearchContext.Provider>
  );
};
export default SearchContextProvider;
