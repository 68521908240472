import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AuthUserType } from '@crema/models/AuthUser';
import jwtAxios, { setAuthToken, setAuthUserId } from './index';
import { useInfoViewActionsContext } from '@crema/context/InfoViewContextProvider';
import { AxiosError, isAxiosError } from 'axios';
import {
  AxiosResponseErrors,
  AxiosResponseWithErrors,
  isResponse401Unauthorized,
  isResponse403Forbidden,
  isResponse422UnprocessableContent,
} from '@crema/services/axios';
import Router from 'next/router';
import { backendUrl } from '@crema/constants/AppConst';
import { destroyCookie, parseCookies } from 'nookies';

interface JWTAuthContextProps {
  user: AuthUserType | null | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
}

interface SignUpProps {
  name: string;
  email: string;
  password: string;
}

interface SignInProps {
  email: string;
  password: string;
  remember_me: boolean;
}

interface PasswordForgetProps {
  email: string;
}

export interface PasswordResetProps {
  password: string;
  password_confirmation: string;
  token: string;
  email: string;
}

export interface PasswordChangeUserProps {
  user_id: number;
  current_password: string;
  password: string;
  password_confirmation: string;
}

export interface JWTAuthActionsProps {
  signUpUser: (
    data: SignUpProps,
    fieldError: (field: string, message: string | undefined) => void
  ) => void;
  signInUser: (
    data: SignInProps,
    fieldError: (field: string, message: string | undefined) => void
  ) => void;
  passwordForgetUser: (
    data: PasswordForgetProps,
    fieldError: (field: string, message: string | undefined) => void
  ) => void;
  passwordResetUser: (
    data: PasswordResetProps,
    fieldError: (field: string, message: string | undefined) => void,
    assignErrors: { [key: string]: string }
  ) => void;
  // eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
  passwordChangeUser: (data: PasswordChangeUserProps) => Promise<any>;
  loadAuthUser: () => void;
  logout: () => void;
}

const JWTAuthContext = createContext<JWTAuthContextProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});
const JWTAuthActionsContext = createContext<JWTAuthActionsProps>({
  signUpUser: () => {},
  signInUser: () => {},
  passwordForgetUser: () => {},
  passwordResetUser: () => {},
  passwordChangeUser: () => Promise.resolve(),
  loadAuthUser: () => {},
  logout: () => {},
});

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

interface JWTAuthAuthProviderProps {
  children: ReactNode;
}

const JWTAuthAuthProvider: React.FC<JWTAuthAuthProviderProps> = ({
  children,
}) => {
  const [firebaseData, setJWTAuthData] = useState<JWTAuthContextProps>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const infoViewActionsContext = useInfoViewActionsContext();

  const loadAuthUser = () => {
    const cookies = parseCookies();

    const token =
      localStorage.getItem('token') ||
      sessionStorage.getItem('token') ||
      cookies['auth-token'];

    if (!token) {
      setJWTAuthData({
        user: undefined,
        isLoading: false,
        isAuthenticated: false,
      });
      return;
    }

    setAuthToken(token);
    jwtAxios
      .get('/user/profile')
      .then(({ data }: { data: { data: AuthUserType } }) => {
        // console.log("get('/user/profile') ", data);

        setAuthUserId(data.data.id);

        setJWTAuthData({
          user: data.data,
          isLoading: false,
          isAuthenticated: true,
        });
      })
      // eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
      .catch((error: any) => {
        if (isAxiosError(error) && error.response?.status === 401) {
          localStorage.removeItem('token');
          sessionStorage.removeItem('token');
          destroyCookie(null, 'auth-token', {
            path: '/',
            sameSite: 'strict',
            maxAge: 0,
          });
          setAuthToken();
          setAuthUserId();
        }

        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
      });
  };

  // const {data: userData, mutate: setUserData, isLoading } = useSWR('/user/profile', fetcher<AuthUserType>, {
  //   revalidateOnFocus: false,
  //   revalidateOnReconnect: false,
  // });
  //
  // useEffect(() => {
  //   setJWTAuthData(prevState => {
  //     return {
  //       ...prevState,
  //       isLoading: isLoading
  //     }
  //   });
  // }, [isLoading]);
  //
  // useEffect(() => {
  //   if (userData) {
  //     setAuthUserId(userData.id);
  //
  //     setJWTAuthData({
  //       user: userData,
  //       isAuthenticated: true,
  //       isLoading: false,
  //     });
  //   }
  // }, [userData]);
  // useEffect(() => {
  //   const cookies = parseCookies();
  //
  //   const token =
  //       localStorage.getItem('token') ||
  //       sessionStorage.getItem('token') ||
  //       cookies['auth-token'];
  //
  //   if (!token) {
  //     setJWTAuthData({
  //       user: undefined,
  //       isLoading: false,
  //       isAuthenticated: false,
  //     });
  //     return;
  //   }
  //
  //   console.log('useEffect token: ', token);
  // }, []);

  useEffect(() => {
    loadAuthUser();
  }, []);

  const fieldErrors = (
    errors: AxiosError,
    setFieldError: (field: string, message: string | undefined) => void,
    assignErrors: { [key: string]: string } = {}
  ) => {
    const response = errors.response as unknown as AxiosResponseWithErrors;
    const dataErrors = response.data.errors as AxiosResponseErrors;

    for (const fieldKey in response.data.errors) {
      const errorMessages = dataErrors[fieldKey];

      if (assignErrors && assignErrors[fieldKey] !== undefined) {
        setFieldError(assignErrors[fieldKey], errorMessages[0]);
      } else {
        setFieldError(fieldKey, errorMessages[0]);
      }

      console.log('fieldKey', fieldKey, errorMessages[0]);
    }
  };

  const signInUser = async (
    {
      email,
      password,
      remember_me,
    }: {
      email: string;
      password: string;
      remember_me: boolean;
    },
    setFieldError: (field: string, message: string | undefined) => void
  ) => {
    infoViewActionsContext.fetchStart();
    try {
      await getCsrfToken();
      const { data } = await jwtAxios.post('/user/login', {
        email,
        password,
        remember_me,
      });

      if (remember_me) {
        localStorage.setItem('token', data.access_token);
        sessionStorage.removeItem('token');
      } else {
        sessionStorage.setItem('token', data.access_token);
        localStorage.removeItem('token');
      }

      setAuthToken(data.access_token);
      const res = await jwtAxios.get('/user/profile');
      // console.log('Profile data', res.data);

      setJWTAuthData({
        user: res.data.data,
        isAuthenticated: true,
        isLoading: false,
      });

      setAuthUserId(res.data.data.id);

      infoViewActionsContext.fetchSuccess();
    } catch (error: unknown) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });

      if (isAxiosError(error)) {
        if (isResponse401Unauthorized(error)) {
          setFieldError('password', 'Invalid email or password');
          infoViewActionsContext.clearInfoView();
        } else if (
          isResponse403Forbidden(error) &&
          error.response.data.message === 'Your email address is not verified.'
        ) {
          setFieldError(
            'password',
            'Email not verified. Check your email for activation code'
          );
          infoViewActionsContext.clearInfoView();
        } else if (isResponse422UnprocessableContent(error)) {
          fieldErrors(error, setFieldError);
          infoViewActionsContext.clearInfoView();
        } else if (error.code === 'ERR_NETWORK') {
          infoViewActionsContext.fetchError(
            'There are problems reaching the api. Check your internet connection.'
          );
        } else {
          console.log('error', error);
          infoViewActionsContext.fetchError('Error not handled');
        }
      } else {
        infoViewActionsContext.fetchError('Something went wrong');
      }
    }
  };

  const signUpUser = async (
    {
      name,
      email,
      password,
    }: {
      name: string;
      email: string;
      password: string;
    },
    setFieldError: (field: string, message: string | undefined) => void
  ) => {
    infoViewActionsContext.fetchStart();
    try {
      const { data } = await jwtAxios.post('/user/register', {
        name,
        email,
        password,
      });
      if (data.success !== true) {
        throw new Error('Something went wrong 1');
      }

      console.log('Register data', data);

      const { data: loginData } = await jwtAxios.post('/user/login', {
        email,
        password,
      });

      // console.log('Login data', loginData);

      sessionStorage.setItem('token', loginData.access_token);
      localStorage.removeItem('token');

      setAuthToken(loginData.access_token);

      const res = await jwtAxios.get('/user/profile');
      setJWTAuthData({
        user: res.data.data,
        isAuthenticated: true,
        isLoading: false,
      });

      setAuthUserId(res.data.data.id);

      infoViewActionsContext.fetchSuccess();
    } catch (err: unknown) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });

      if (isAxiosError(err)) {
        if (isResponse422UnprocessableContent(err)) {
          fieldErrors(err, setFieldError);
          infoViewActionsContext.clearInfoView();
        } else {
          infoViewActionsContext.fetchError('Something went wrong');
        }
      } else {
        infoViewActionsContext.fetchError('Something went wrong');
      }
    }
  };

  const passwordForgetUser = async (
    {
      email,
    }: {
      email: string;
    },
    setFieldError: (field: string, message: string | undefined) => void
  ) => {
    infoViewActionsContext.fetchStart();
    try {
      const { data } = await jwtAxios.post('/user/forgot', { email });
      if (data.success !== true) {
        throw new Error('Something went wrong 1');
      }

      console.log('Forget data', data);

      infoViewActionsContext.showMessage(data.message);
    } catch (err: unknown) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });

      if (isAxiosError(err)) {
        if (isResponse422UnprocessableContent(err)) {
          fieldErrors(err, setFieldError);
          infoViewActionsContext.clearInfoView();
        } else {
          infoViewActionsContext.fetchError('Something went wrong');
        }
      } else {
        infoViewActionsContext.fetchError('Something went wrong');
      }
    }
  };

  const passwordResetUser = async (
    { token, email, password, password_confirmation }: PasswordResetProps,
    setFieldError: (field: string, message: string | undefined) => void,
    assignErrors: {
      [key: string]: string;
    }
  ) => {
    infoViewActionsContext.fetchStart();
    try {
      const { data } = await jwtAxios
        .post('/user/password-update', {
          token,
          email,
          password,
          password_confirmation,
        })
        .catch((err: unknown) => {
          console.log('err in post', err);

          if (isAxiosError(err)) {
            if (isResponse422UnprocessableContent(err)) {
              fieldErrors(err, setFieldError);
              infoViewActionsContext.clearInfoView();
            } else {
              infoViewActionsContext.fetchError('Something went wrong');
            }
          } else {
            infoViewActionsContext.fetchError('Something went wrong');
          }
        });

      if (data.success !== true) {
        throw new Error('Something went wrong 1');
      }

      console.log('password-update data', data);

      // infoViewActionsContext.fetchSuccess();
      infoViewActionsContext.showMessage(data.message);

      Router.push('/signin');
    } catch (err: unknown) {
      console.log('err', err);

      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });

      if (isAxiosError(err)) {
        if (isResponse401Unauthorized(err)) {
          infoViewActionsContext.clearInfoView();
        } else if (isResponse422UnprocessableContent(err)) {
          fieldErrors(err, setFieldError, assignErrors);
          infoViewActionsContext.clearInfoView();
        } else {
          infoViewActionsContext.fetchError('Something went wrong');
        }
      } else {
        infoViewActionsContext.fetchError('Something went wrong');
      }
    }
  };

  const passwordChangeUser = async ({
    user_id,
    current_password,
    password,
    password_confirmation,
  }: PasswordChangeUserProps) => {
    return new Promise((resolve, reject) => {
      try {
        jwtAxios
          .post(`/user/${user_id}/password-change`, {
            current_password,
            password,
            password_confirmation,
          })
          .then((data: any) => {
            if (data.data.success === true) {
              resolve(true);
            }

            reject(data.data);
          })
          .catch((err: unknown) => {
            console.log('err in post', err);
            resolve(err);
          });
      } catch (err: unknown) {
        console.log('err', err);
        reject(err);
      }
    });
  };

  const logout = async () => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    // email verification notification
    sessionStorage.removeItem('verify_show_notice');
    destroyCookie(null, 'auth-token', {
      path: '/',
      sameSite: 'strict',
      maxAge: 0,
    });
    setAuthToken();
    setAuthUserId();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
  };

  const getCsrfToken = async () => {
    try {
      const { data } = await jwtAxios.get(backendUrl + 'sanctum/csrf-cookie');
      return data;
    } catch (err: unknown) {
      return false;
    }
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          passwordForgetUser,
          passwordResetUser,
          passwordChangeUser,
          loadAuthUser,
          logout,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;
