import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { contextReducer, InFoViewActions } from './InfoViewReducer';
import { MessageTypeVariant } from '@crema/components/AppHeaderMessages';
import { convertToString, hashString } from '@crema/helpers';
import { readFromStorage } from '@crema/helpers';
import { PopupUniqueType } from '@src/modules/guides/Create';

export type InfoViewData = {
  error: string;
  message: string;
  warning: string;
  loading: boolean;
  headerMessage: HeaderMessageType | false | true | null;
  clearedHeaderMessage:
    | boolean
    | {
        groups: string[];
      };
  popupMessage: PopupMessageType | false | true | null;
  clearedPopupMessage:
    | boolean
    | {
        groups: string[];
      };
  ignoredPopupMessages: string[];
  counters: Record<string, number>;
  bagCounters: Record<BagType, Record<string, number>>;
  bagCountersAck: Record<BagType, Record<string, number>>;
};

export type BagType = 'ephemeral' | 'session' | 'user';

export type HeaderMessageType = {
  message: string | React.ReactElement;
  type: MessageTypeVariant;
  autoHide: boolean;
  canClose: boolean;
  canIgnore: boolean;
  useCounters: boolean;
  counterId?: string;
  popupUnique?: PopupUniqueType;
  icon?: string | React.ElementType;
  group?: string;
};

export type PopupMessageType = {
  message: string | React.ReactElement;
  type: MessageTypeVariant;
  autoHide: boolean;
  canClose: boolean;
  canIgnore: boolean;
  useCounters: boolean;
  counterId?: string;
  popupUnique?: PopupUniqueType;
  icon?: string | React.ElementType;
  group?: string;
};

export type InfoViewActions = {
  fetchStart: () => void;
  fetchStop: () => void;
  fetchSuccess: () => void;
  fetchError: (error: string | React.ReactElement) => void;
  fetchWarning: (message: string | React.ReactElement) => void;
  showMessage: (message: string | React.ReactElement) => void;
  clearInfoView: () => void;
  clearMessages: () => void;
  setHeaderMessage: (message: HeaderMessageType) => void;
  clearHeaderMessages: () => void;
  clearHeaderGroup: (group: string) => void;
  clearHeaderGroups: (groups: string[]) => void;
  consumeClearHeaderMessages: () => void;
  setPopupMessage: (message: PopupMessageType) => void;
  clearPopupMessages: () => void;
  clearPopupGroup: (group: string) => void;
  clearPopupGroups: (groups: string[]) => void;
  consumeClearPopupMessages: () => void;
  // eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
  ignorePopupMessage: (message: any) => void;
  // eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
  isPopupIgnored: (message: any) => boolean;
  setCounter: (key: string, value: number) => void;
  incrementCounter: (key: string, value: number) => void;
  getCounter: (key: string) => number;

  setBagCounter: (type: BagType, key: string, value: number) => void;
  incrementBagCounter: (type: BagType, key: string, value: number) => void;
  getBagCounter: (type: BagType, key: string) => number;

  setBagCounterAck: (type: BagType, key: string, value: number) => void;
  incrementBagCounterAck: (type: BagType, key: string, value: number) => void;
  getBagCounterAck: (type: BagType, key: string) => number;
};

export const ContextState: InfoViewData = {
  loading: false,
  error: '',
  message: '',
  warning: '',
  headerMessage: false,
  clearedHeaderMessage: false,
  popupMessage: false,
  clearedPopupMessage: false,
  ignoredPopupMessages: [],
  counters: {},
  bagCounters: {
    ephemeral: {},
    session: {},
    user: {},
  },
  bagCountersAck: {
    ephemeral: {},
    session: {},
    user: {},
  },
};

const InfoViewContext = createContext<InfoViewData>(ContextState);
const InfoViewActionsContext = createContext<InfoViewActions>({
  fetchStart: () => {},
  fetchStop: () => {},
  fetchSuccess: () => {},
  fetchError: () => {},
  fetchWarning: () => {},
  showMessage: () => {},
  clearInfoView: () => {},
  clearMessages: () => {},
  setHeaderMessage: () => {},
  clearHeaderMessages: () => {},
  clearHeaderGroup: () => {},
  clearHeaderGroups: () => {},
  consumeClearHeaderMessages: () => {},
  setPopupMessage: () => {},
  clearPopupMessages: () => {},
  clearPopupGroup: () => {},
  clearPopupGroups: () => {},
  consumeClearPopupMessages: () => {},
  ignorePopupMessage: () => {},
  isPopupIgnored: () => false,
  setCounter: () => {},
  incrementCounter: () => {},
  getCounter: () => 0,
  setBagCounter: () => {},
  incrementBagCounter: () => {},
  getBagCounter: () => 0,
  setBagCounterAck: () => {},
  incrementBagCounterAck: () => {},
  getBagCounterAck: () => 0,
});

export const useInfoViewContext = () => useContext(InfoViewContext);
export const useInfoViewActionsContext = () =>
  useContext(InfoViewActionsContext);

type InfoViewContextProviderProps = {
  children: ReactNode;
};
const InfoViewContextProvider: React.FC<InfoViewContextProviderProps> = (
  props
) => {
  const [state, dispatch] = useReducer(
    contextReducer,
    ContextState,
    (initial) => {
      const counters = readFromStorage('infoViewCounters', {});
      const ignoredPopupMessages = readFromStorage('infoViewPopupIgnored', []);
      const infoViewPopupBagUser = readFromStorage('infoViewPopupBagUser', {});
      const infoViewPopupBagSession = readFromStorage(
        'infoViewPopupBagSession',
        {}
      );
      const infoViewPopupBagAckUser = readFromStorage(
        'infoViewPopupBagAckUser',
        {}
      );
      const infoViewPopupBagAckSession = readFromStorage(
        'infoViewPopupBagAckSession',
        {}
      );

      // console.log('counters at init:', {
      //   counters,
      //   ignoredPopupMessages,
      //   infoViewPopupBagUser,
      //   infoViewPopupBagSession
      // });

      return {
        ...initial,
        counters,
        ignoredPopupMessages,
        bagCounters: {
          user: infoViewPopupBagUser,
          session: infoViewPopupBagSession,
          ephemeral: {},
        },
        bagCountersAck: {
          user: infoViewPopupBagAckUser,
          session: infoViewPopupBagAckSession,
          ephemeral: {},
        },
      };
    }
  );

  const fetchStart = useCallback(() => {
    dispatch({ type: InFoViewActions.FETCH_STARTS });
  }, []);

  const fetchStop = useCallback(() => {
    dispatch({ type: InFoViewActions.FETCH_STOP });
  }, []);

  const fetchSuccess = useCallback(() => {
    dispatch({ type: InFoViewActions.FETCH_SUCCESS });
  }, []);

  const fetchWarning = useCallback((message: string | React.ReactElement) => {
    dispatch({ type: InFoViewActions.FETCH_WARNING, payload: message });
  }, []);

  const fetchError = useCallback((error: string | React.ReactElement) => {
    dispatch({ type: InFoViewActions.SET_ERROR, payload: error });
  }, []);

  const showMessage = useCallback((message: string | React.ReactElement) => {
    dispatch({ type: InFoViewActions.SET_MESSAGE, payload: message });
  }, []);

  const clearInfoView = useCallback(() => {
    dispatch({ type: InFoViewActions.CLEAR_INFOVIEW });
  }, []);

  const clearMessages = useCallback(() => {
    dispatch({ type: InFoViewActions.CLEAR_MESSAGE });
  }, []);

  const consumeMessages = useCallback(() => {
    dispatch({ type: InFoViewActions.CONSUME_MESSAGE });
  }, []);

  const setHeaderMessage = useCallback((message: HeaderMessageType) => {
    //    console.log('setHeaderMessage', message);
    dispatch({ type: InFoViewActions.HEADER_MESSAGE, payload: message });
  }, []);

  const clearHeaderMessages = useCallback(() => {
    dispatch({ type: InFoViewActions.CLEAR_HEADER_MESSAGE, groups: [] });
  }, []);

  const clearHeaderGroup = useCallback((group: string) => {
    dispatch({ type: InFoViewActions.CLEAR_HEADER_MESSAGE, groups: [group] });
  }, []);

  const clearHeaderGroups = useCallback((groups: string[]) => {
    dispatch({ type: InFoViewActions.CLEAR_HEADER_MESSAGE, groups: groups });
  }, []);

  const consumeClearHeaderMessages = useCallback(() => {
    dispatch({ type: InFoViewActions.CONSUME_CLEAR_HEADER_MESSAGE });
  }, []);

  const setPopupMessage = useCallback((message: PopupMessageType) => {
    // console.log('setPopupMessage', message);
    dispatch({ type: InFoViewActions.POPUP_MESSAGE, payload: message });
  }, []);

  const clearPopupMessages = useCallback(() => {
    dispatch({ type: InFoViewActions.CLEAR_POPUP_MESSAGE, groups: [] });
  }, []);

  const clearPopupGroup = useCallback((group: string) => {
    dispatch({ type: InFoViewActions.CLEAR_POPUP_MESSAGE, groups: [group] });
  }, []);

  const clearPopupGroups = useCallback((groups: string[]) => {
    dispatch({ type: InFoViewActions.CLEAR_POPUP_MESSAGE, groups: groups });
  }, []);

  const consumeClearPopupMessages = useCallback(() => {
    dispatch({ type: InFoViewActions.CONSUME_CLEAR_POPUP_MESSAGE });
  }, []);

  const ignorePopupMessage = useCallback((message: string) => {
    dispatch({
      type: InFoViewActions.IGNORE_POPUP_MESSAGE,
      payload: hashString(convertToString(message)).toString(),
    });

    return state.message
      ? state.message
      : state.warning
      ? state.warning
      : state.error
      ? state.error
      : '';

    // return state.message || state.warning || state.error || '';
  }, []);

  // eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
  const isPopupIgnored = (message: any) => {
    return state.ignoredPopupMessages.includes(
      hashString(convertToString(message)).toString()
    );
  };

  const setCounter = useCallback((key: string, value: number) => {
    // console.log('setCounter', key, state.counters[key], value);
    dispatch({ type: InFoViewActions.SET_COUNTER, payload: { key, value } });
  }, []);

  const incrementCounter = useCallback((key: string, value = 1) => {
    // console.log('incrementCounter', key, state.counters[key], value);
    dispatch({
      type: InFoViewActions.INCREMENT_COUNTER,
      payload: { key, value },
    });
  }, []);

  const getCounter = (key: string) => {
    // console.log('getCounter', key, state.counters[key] || 0);
    return state.counters[key] || 0;
  };

  const setBagCounter = useCallback(
    (type: BagType, key: string, value: number) => {
      // console.log('setBagCounter', key, state.bagCounters[type][key], value);
      dispatch({
        type: InFoViewActions.SET_BAG_COUNTER,
        payload: { type, key, value },
      });
    },
    []
  );

  const incrementBagCounter = useCallback(
    (type: BagType, key: string, value = 1) => {
      // console.log('incrementBagCounter', key, state.bagCounters[type][key], value);
      dispatch({
        type: InFoViewActions.INCREMENT_BAG_COUNTER,
        payload: { type, key, value },
      });
    },
    []
  );

  const getBagCounter = (type: BagType, key: string) => {
    // console.log('getCounter', key, state.bagCounters[type][key] || 0);
    return state.bagCounters[type][key] || 0;
  };

  const setBagCounterAck = useCallback(
    (type: BagType, key: string, value: number) => {
      // console.log('setBagCounter', key, state.bagCounters[type][key], value);
      dispatch({
        type: InFoViewActions.SET_BAG_COUNTER_ACK,
        payload: { type, key, value },
      });
    },
    []
  );

  const incrementBagCounterAck = useCallback(
    (type: BagType, key: string, value = 1) => {
      // console.log('incrementBagCounter', key, state.bagCounters[type][key], value);
      dispatch({
        type: InFoViewActions.INCREMENT_BAG_COUNTER_ACK,
        payload: { type, key, value },
      });
    },
    []
  );

  const getBagCounterAck = (type: BagType, key: string) => {
    // console.log('getCounter', key, state.bagCounters[type][key] || 0);
    return state.bagCountersAck[type][key] || 0;
  };

  useEffect(() => {
    // console.log('counters changed', {
    //   counters: state.counters,
    //   ignoredPopupMessages: state.ignoredPopupMessages,
    //   bagUser: state.bagCounters.user,
    //   bagSession: state.bagCounters.session
    // });

    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('infoViewCounters', JSON.stringify(state.counters));
      localStorage.setItem(
        'infoViewPopupIgnored',
        JSON.stringify(state.ignoredPopupMessages)
      );

      // bag
      localStorage.setItem(
        'infoViewPopupBagUser',
        JSON.stringify(state.bagCounters.user)
      );
      localStorage.setItem(
        'infoViewPopupBagSession',
        JSON.stringify(state.bagCounters.session)
      );

      // bagAck
      localStorage.setItem(
        'infoViewPopupBagAckUser',
        JSON.stringify(state.bagCountersAck.user)
      );
      localStorage.setItem(
        'infoViewPopupBagAckSession',
        JSON.stringify(state.bagCountersAck.session)
      );
    }
  }, [
    state.counters,
    state.ignoredPopupMessages,
    state.bagCounters.user,
    state.bagCounters.session,
    state.bagCountersAck.user,
    state.bagCountersAck.session,
  ]);

  return (
    <InfoViewContext.Provider value={state}>
      <InfoViewActionsContext.Provider
        value={{
          fetchStart,
          fetchStop,
          fetchSuccess,
          fetchWarning,
          fetchError,
          showMessage,
          clearInfoView,
          clearMessages,
          setHeaderMessage,
          clearHeaderMessages,
          clearHeaderGroup,
          clearHeaderGroups,
          consumeClearHeaderMessages,
          setPopupMessage,
          clearPopupMessages,
          clearPopupGroup,
          clearPopupGroups,
          consumeClearPopupMessages,
          ignorePopupMessage,
          isPopupIgnored,
          setCounter,
          incrementCounter,
          getCounter,
          setBagCounter,
          incrementBagCounter,
          getBagCounter,
          setBagCounterAck,
          incrementBagCounterAck,
          getBagCounterAck,
        }}
      >
        {props.children}
      </InfoViewActionsContext.Provider>
    </InfoViewContext.Provider>
  );
};

export default InfoViewContextProvider;
