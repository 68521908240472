import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import AppContextProvider from '@crema/context/AppContextProvider';
import AppThemeProvider from '@crema/context/AppThemeProvider';
import AppLocaleProvider from '@crema/context/AppLocaleProvider';
import AppAuthProvider from '../src/core/AppAuthProvider';
import AuthRoutes from '@crema/components/AuthRoutes';

import '../public/styles/vendors/index.css';

import AppPageMeta from '@crema/components/AppPageMeta';
import InfoViewContextProvider from '@crema/context/InfoViewContextProvider';
import createEmotionCache from '../createEmotionCache';
import { AppProps } from 'next/app';
import { ErrorInfo, StrictMode } from 'react';
import SearchContextProvider from '@src/modules/apps/context/SearchContextProvider';
import Bugsnag from '../libs/services/bugsnag';
import { ensureUniqueKey } from '@crema/helpers';
import Head from 'next/head';
import { OnErrorCallback } from '@bugsnag/core';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const ErrorView = (params: {
  error: Error;
  info: ErrorInfo;
  clearError: () => void;
}) => {
  console.error('ErrorView: ', params);

  return (
    <div>
      <p>Inform users of an error in the component tree.</p>
    </div>
  );
};

type Props = {
  children?: React.ReactNode;
  onError?: OnErrorCallback;
  FallbackComponent?: React.ComponentType<{
    error: Error;
    info: React.ErrorInfo;
    clearError: () => void;
  }>;
};

const FallbackErrorBoundary = ({ children }: Props) => {
  return <>{children}</>;
};

const ErrorBoundary = Bugsnag.isStarted()
  ? Bugsnag.getPlugin('react')?.createErrorBoundary(React) ||
    FallbackErrorBoundary
  : FallbackErrorBoundary;

export default function MyApp(props: AppProps) {
  const { Component, pageProps } = props;
  ensureUniqueKey();

  // TEST_OPTION use <StrictMode></StrictMode> instead of <></>
  return (
    <StrictMode>
      <ErrorBoundary FallbackComponent={ErrorView}>
        <AppPageMeta {...(pageProps.meta || {})} />
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=5.0"
          />
          <noscript>
            <style>
              {`
                .AuthWrapper-container {
                max-width: 400px !important;

                .MuiCheckbox-root .MuiSvgIcon-root {
                  display: none;
                }
              }
                /* Other styles specifically for no-JS scenario */
              `}
            </style>
          </noscript>
        </Head>
        <CacheProvider value={clientSideEmotionCache}>
          <AppContextProvider>
            <AppThemeProvider>
              <AppLocaleProvider>
                <InfoViewContextProvider>
                  <AppAuthProvider>
                    <AuthRoutes>
                      <CssBaseline />
                      <SearchContextProvider>
                        <Component {...pageProps} />
                      </SearchContextProvider>
                    </AuthRoutes>
                  </AppAuthProvider>
                </InfoViewContextProvider>
              </AppLocaleProvider>
            </AppThemeProvider>
          </AppContextProvider>
        </CacheProvider>
      </ErrorBoundary>
    </StrictMode>
  );
}
